exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-get-involved-tsx": () => import("./../../../src/pages/get-involved.tsx" /* webpackChunkName: "component---src-pages-get-involved-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-package-tsx": () => import("./../../../src/pages/package.tsx" /* webpackChunkName: "component---src-pages-package-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

