import React, { useState } from 'react';

type Organisation = {
    name: string;
    official_name: string;
    building: string;
    address_first: string;
    address_second: string;
    postcode: string;
    city: string;
    country: string;
};

type User = {
    name: string;
    email: string;
    role: string;
    password?: string;
};

type State = {
    organisation: Organisation;
    user: User;
    token: string;
};

const defaultState: {
    organisation: Organisation;
    user: User;
    token: string;
    setEmail: (email: string) => void;
    setOrg: (organisation: Organisation) => void;
    setUser: (user: User) => void;
    setInviteInfo: (token: string, user: User, orgName: string) => void;
} = {
    organisation: {
        name: '',
        official_name: '',
        building: '',
        address_first: '',
        address_second: '',
        postcode: '',
        city: '',
        country: '',
    },
    user: {
        name: '',
        email: '',
        role: '',
        password: '',
    },
    token: '',
    setEmail: () => {},
    setOrg: () => {},
    setUser: () => {},
    setInviteInfo: () => {},
};
const AppContext = React.createContext(defaultState);

const AppProvider = (props: any) => {
    const [state, setState] = useState<State>({
        organisation: {
            name: '',
            official_name: '',
            building: '',
            address_first: '',
            address_second: '',
            postcode: '',
            city: '',
            country: '',
        },
        user: {
            name: '',
            email: '',
            role: '',
            password: '',
        },
        token: '',
    });

    const setEmail = (email: string) => {
        setState({
            ...state,
            user: {
                ...state.user,
                email,
            },
        });
    };

    const setInviteInfo = (token: string, user: User, orgName: string) => {
        setState({
            ...state,
            user: {
                ...state.user,
                ...user,
            },
            organisation: {
                ...state.organisation,
                name: orgName,
            },
            token,
        });
    };

    const setOrg = (organisation: Organisation) => {
        setState({
            ...state,
            organisation,
        });
    };

    const setUser = (user: User) => {
        setState({
            ...state,
            user,
        });
    };

    const value = {
        ...state,
        setEmail,
        setOrg,
        setUser,
        setInviteInfo,
    };

    return (
        <AppContext.Provider value={value}>
            {props.children}
        </AppContext.Provider>
    );
};
export default AppContext;
export { AppProvider };
